<md-nav-list class="main-nav">
    <a md-list-item [routerLink]="['/home']">
        <md-icon svgIcon="home"></md-icon>&nbsp;
        Home
    </a>
    <a md-list-item [routerLink]="['/products']">
        <md-icon svgIcon="view-sequential"></md-icon>&nbsp;
        Product List
    </a>
    <a md-list-item [routerLink]="['/versions']">
        <md-icon svgIcon="view-list"></md-icon>&nbsp;
        Version List
    </a>
    <a md-list-item [routerLink]="['/productedit']">
        <md-icon svgIcon="plus-box"></md-icon>&nbsp;
        Add Product
    </a>
    <a md-list-item [routerLink]="['/versionedit']">
        <md-icon svgIcon="file-plus"></md-icon>&nbsp;
        Add Version
    </a>
</md-nav-list>
