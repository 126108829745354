<h2><a [routerLink]="['/products']"><md-icon svgIcon="arrow-left"></md-icon></a> Product Details</h2>
<div class="product-container">
    <md-card class="product-card">
        <md-card-header>
            <img md-card-avatar src="{{product.IconUrl}}" />
            <md-card-title>{{product.Name}}</md-card-title>
            <md-card-subtitle>{{product.UpdateId}}</md-card-subtitle>
        </md-card-header>
        <img md-card-image src="{{product.ImageUrl}}" />
        <md-card-content flex>
            <p>
                {{product.Description}}
            </p>
            <div class="product-status">
                Status: {{releaseStatus[product.Status][0]}}
            </div>
            <h3>Software Versions</h3>
            <md-list>
                <h3 md-subheader>Latest Versions</h3>
                <md-list-item *ngIf="latestVersion" (click)="router.navigate(['/version', latestVersion.Id])" style="cursor:pointer">
                    <md-icon svgIcon="file-check"></md-icon>
                    <h4 md-line>Latest</h4>
                    <p md-line>{{latestVersion.VersionName}} ({{latestVersion.VersionNumber}}) - {{latestVersion.ReleaseDate | date}}: {{releaseStatus[latestVersion.Status][0]}}</p>
                </md-list-item>
                <md-list-item *ngIf="latestBeta" (click)="router.navigate(['/version', latestBeta.Id])" style="cursor:pointer">
                    <md-icon svgIcon="beta"></md-icon>
                    <h4 md-line>Beta</h4>
                    <p md-line>{{latestBeta.VersionName}} ({{latestBeta.VersionNumber}}) - {{latestBeta.ReleaseDate}}</p>
                </md-list-item>
                <md-list-item *ngIf="latestAlpha" (click)="router.navigate(['/version', latestAlpha.Id])" style="cursor:pointer">
                    <md-icon svgIcon="alpha"></md-icon>
                    <h4 md-line>Alpha</h4>
                    <p md-line>{{latestAlpha.VersionName}} ({{latestAlpha.VersionNumber}}) - {{latestAlpha.ReleaseDate}}</p>
                </md-list-item>
                <md-list-item *ngIf="latestProto" (click)="router.navigate(['/version', latestProto.Id])" style="cursor:pointer">
                    <md-icon svgIcon="test-tube"></md-icon>
                    <h4 md-line>Prototype</h4>
                    <p md-line>{{latestProto.VersionName}} ({{latestProto.VersionNumber}}) - {{latestProto.ReleaseDate}}</p>
                </md-list-item>
                <md-divider></md-divider>
                <h3 md-subheader>All Versions</h3>
                <md-list-item *ngFor="let version of versions" (click)="router.navigate(['/version', version.Id])">
                    <md-icon svgIcon="file"></md-icon>
                    <h4 md-line>{{version.VersionName}} ({{version.VersionNumber}})</h4>
                    <p md-line>{{releaseStatus[version.Status][0]}} - {{version.ReleaseDate | date}}</p>
                </md-list-item>
            </md-list>
        </md-card-content>
        <md-card-footer>
            <div class="product-button-container">
                <a md-raised-button [routerLink]="['/productedit', product.Id]"><md-icon svgIcon="pencil"></md-icon>&nbsp;Edit</a>
            </div>
        </md-card-footer>
    </md-card>
</div>
<div class="busy-overlay" *ngIf="busy">
    <md-progress-spinner class="product-spinner"
                         color="white"
                         mode="indeterminate">
    </md-progress-spinner>
</div>
