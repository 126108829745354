<md-toolbar color="primary" id="app-toolbar">
    <button class="app-icon-button" (click)="sidenav.toggle()">
        <md-icon svgIcon="menu"></md-icon>
    </button>
    <span class="page-title">Subsite Software Downloads</span>

    <span class="app-toolbar-filler"></span>
    <button md-raised-button (click)="auth.login()" *ngIf="!auth.authenticated" color="primary"><md-icon svgIcon="login"></md-icon></button>
    <button md-raised-button (click)="auth.logout()" *ngIf="auth.authenticated" color="primary"><md-icon svgIcon="logout"></md-icon></button>
</md-toolbar>
<md-sidenav-container id="app-container">

    <md-sidenav #sidenav mode="side" id="app-sidenav">
        <nav-menu></nav-menu>
    </md-sidenav>

    
    <div class="app-content">
        <router-outlet></router-outlet>
    </div>
    
</md-sidenav-container>
<footer id="app-footer">
    <div>&copy; 2017 <a href="https://charlesmachine.works/">The Charles Machine Works, Inc.</a></div>
</footer>
