<h2>Version Details</h2>
<div class="version-container">
    <md-card class="version-card">
        <md-card-header>
            <md-card-title>{{version.VersionName}}</md-card-title>
            <md-card-subtitle>{{version.VersionNumber}}</md-card-subtitle>
        </md-card-header>
        <md-card-content flex>
            <table class="version-table">
                <tr>
                    <td>Id</td>
                    <td>{{version.Id}}</td>
                </tr>
                <tr>
                    <td>Release Date</td>
                    <td>{{version.ReleaseDate | date}}</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>{{releaseStatus[version.Status][0]}}</td>
                </tr>
                <tr>
                    <td>Release Notes</td>
                    <td><markdown [data]='version.ReleaseNotes'></markdown></td>
                </tr>
                <tr>
                    <td>Engineering Notes</td>
                    <td><markdown [data]='version.EngrNotes'></markdown></td>
                </tr>
                <tr>
                    <td>File Hash</td>
                    <td style="text-overflow: ellipsis;">{{version.Hash}}</td>
                </tr>
            </table>
            <br />
            <a md-raised-button href="{{version.Url}}"><i class="mdi mdi-download"></i>Download File</a>

            <md-list>
                <h4 md-subheader>Associated Products</h4>
                <md-list-item *ngFor="let product of products">
                    <a md-button [routerLink]="['/product', product.Id]">
                        <img class="product-icon" src="{{product.IconUrl}}" />
                        {{product.Name}} ({{product.UpdateId}})
                    </a>
                </md-list-item>
            </md-list>
        </md-card-content>
        <md-card-footer>
            <div class="version-button-container">
                <a md-raised-button [routerLink]="['/versionedit', {id: version.Id}]"><i class="mdi mdi-pencil"></i>Edit</a>
            </div>
        </md-card-footer>
    </md-card>
</div>
<div class="busy-overlay" *ngIf="busy">
    <md-progress-spinner class="busy-spinner"
                         color="white"
                         mode="indeterminate">
    </md-progress-spinner>
</div>

