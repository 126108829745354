<h2><a [routerLink]="['/products']"><md-icon svgIcon="arrow-left"></md-icon></a>Edit a Product</h2>
<form class="product-form" style="max-width: 500px;">
    <h3>Product Properties</h3>

    <div mdTooltip="" mdTooltipPosition="after" *ngIf="product.Id > 0">
        <md-input-container class="product-full-width">
            <input mdInput placeholder="Product Id" value="Id" id="Id" disabled [(ngModel)]="product.Id" name="Id" />
        </md-input-container>
    </div>
    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="product-full-width">
            <input mdInput placeholder="Product Name" value="Name" id="Name" required [(ngModel)]="product.Name" name="Name" />
        </md-input-container>
    </div>
    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="product-full-width">
            <input mdInput placeholder="Update Id" value="UpdateId" id="UpdateId" required [(ngModel)]="product.UpdateId" name="UpdateId" pattern="[a-z0-9-]+" />
        </md-input-container>
    </div>
    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="product-full-width">
            <textarea mdInput placeholder="Product Description" value="Description" id="Description" [(ngModel)]="product.Description" name="Description">
            </textarea>
        </md-input-container>
    </div>
    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="product-full-width">
            <input mdInput placeholder="Icon" value="IconUrl" id="IconUrl" required [(ngModel)]="product.IconUrl" name="IconUrl" />
        </md-input-container>
    </div>
    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="product-full-width">
            <input mdInput placeholder="Image" value="ImageUrl" id="ImageUrl" required [(ngModel)]="product.ImageUrl" name="ImageUrl" />
        </md-input-container>
    </div>
    <div mdTooltip="Product release status" mdTooltipPosition="after">
        <md-select class="product-full-width" placeholder="Release Status" [(ngModel)]="product.Status" name="Status">
            <md-option *ngFor="let status of releaseStatus" [value]="status[1]">{{status[0]}}</md-option>
        </md-select>
    </div>
    <div>
        <button md-raised-button class="product-button" (click)="saveProduct(product);" color="primary">Save</button>
        <button md-raised-button class="product-button" (click)="cancel();">Cancel</button>
    </div>
    <div class="busy-overlay" *ngIf="busy">
        <md-progress-spinner class="product-spinner"
                             color="white"
                             mode="indeterminate">
        </md-progress-spinner>
    </div>
    
</form>
