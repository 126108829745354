<md-card>
  <md-card-header>
    <md-card-title>Software Downloads</md-card-title>
  </md-card-header>
  <md-card-content>
    Get the latest software for your Subsite product.
  </md-card-content>
  <br />
  <md-card-actions>
    <a md-raised-button [routerLink]="['/products']" color="primary">Products</a>
  </md-card-actions>
</md-card>
