<h2>Edit a Version</h2>
<form class="version-form" style="max-width: 500px;">
    <h3>Version Properties</h3>

    <div mdTooltip="Version ID assigned by the system" mdTooltipPosition="after" *ngIf="version.Id > 0">
        <md-input-container class="version-full-width">
            <input mdInput placeholder="Version Id" value="Id" id="Id" disabled [(ngModel)]="version.Id" name="Id" />
        </md-input-container>
    </div>
    <div mdTooltip="Friendly name of the version (ex. 1, 16)" mdTooltipPosition="after">
        <md-input-container class="version-full-width">
            <input mdInput placeholder="Version Name" value="VersionName" id="VersionName" required [(ngModel)]="version.VersionName" name="VersionName" />
        </md-input-container>
    </div>
    <div mdTooltip="Version number for sorting what is 'newer'. (ex. '1.2.3', '2', '444.3.123')" mdTooltipPosition="after">
        <md-input-container class="version-full-width">
            <input mdInput placeholder="Version Number" value="VersionNumber" id="VersionNumber" required [(ngModel)]="version.VersionNumber" name="VersionNumber" pattern="^((\d*)[.])*\d*$" />
        </md-input-container>
    </div>

    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="version-full-width">
            <textarea mdInput placeholder="Release Notes" value="ReleaseNotes" id="ReleaseNotes" [(ngModel)]="version.ReleaseNotes" name="ReleaseNotes">
            </textarea>
        </md-input-container>
    </div>
    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="version-full-width">
            <textarea mdInput placeholder="Engineering Notes" value="EngrNotes" id="EngrNotes" [(ngModel)]="version.EngrNotes" name="EngrNotes">
            </textarea>
        </md-input-container>
    </div>
    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="version-full-width">
            <input mdInput placeholder="Release Date" [ngModel]="version.ReleaseDate | date:'yyyy-MM-dd'" (ngModelChange)="version.ReleaseDate = $event" type="date" name="ReleaseDate" />
        </md-input-container>
    </div>
    <div mdTooltip="Version release status" mdTooltipPosition="after">
        <md-select class="version-full-width" placeholder="Release Status" [(ngModel)]="version.Status" name="Status">
            <md-option *ngFor="let status of releaseStatus" [value]="status[1]">{{status[0]}}</md-option>
        </md-select>
    </div>

    <h4>File Upload</h4>
    <input class="version-upload" type="file" (change)="fileUpload($event)" placeholder="Upload" accept=".*" />

    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="version-full-width">
            <input mdInput placeholder="File URL" value="Url" id="Url" disabled [(ngModel)]="version.Url" name="Url" />
        </md-input-container>
    </div>
    <div mdTooltip="" mdTooltipPosition="after">
        <md-input-container class="version-full-width">
            <input mdInput placeholder="File Hash" value="Hash" id="Hash" disabled [(ngModel)]="version.Hash" name="Hash" />
        </md-input-container>
    </div>

    <h4>Products</h4>
    <md-input-container>
        <input mdInput placeholder="Linked Products" [mdAutocomplete]="auto" [formControl]="productCtrl">
    </md-input-container>
    <md-autocomplete #auto="mdAutocomplete">
        <md-option *ngFor="let product of filteredProducts | async" [value]="product.Name" (onSelectionChange)="onProductSelected(product, $event)">
            {{ product.Name }} ({{product.UpdateId}})
        </md-option>
    </md-autocomplete>
    <md-chip-list class="version-full-width" #chipList>
        <md-chip *ngFor="let product of selectedProducts" disabled>
            <span>
                <strong>{{product.UpdateId}}</strong>
                <em>({{product.Name}})</em>
            </span>
            <span (click)="removeSelectedProduct(product)"><i class="mdi mdi-close-circle-outline"></i></span>
        </md-chip>
    </md-chip-list>

    <div>
        <button md-raised-button class="version-button" (click)="saveVersion(version, selectedProducts);" color="primary">Save</button>
        <button md-raised-button class="version-button" (click)="cancel();">Cancel</button>
    </div>
    <div class="busy-overlay" *ngIf="busy">
        <md-progress-spinner class="busy-spinner"
                                color="white"
                                mode="indeterminate">
        </md-progress-spinner>
    </div>

</form>
