<h2>Version List</h2>
<div class="versions-container">
    <md-card class="version-card" *ngFor="let version of versions">
        <md-card-header (click)="router.navigate(['/version', version.Id])" style="cursor:pointer">
            <md-card-title>{{version.VersionName}}</md-card-title>
            <md-card-subtitle>{{version.VersionNumber}}</md-card-subtitle>
        </md-card-header>
        <md-card-content flex>
            <p>
                {{version.ReleaseDate | date}}
            </p>
        </md-card-content>
        <md-card-footer>
            <div class="version-status">
                Status: {{releaseStatus[version.Status][0]}}
            </div>
            <div class="version-button-container">
                <a md-raised-button [routerLink]="['/version', version.Id]"><md-icon svgIcon="information-outline"></md-icon>&nbsp;Details</a>
                <a md-raised-button [routerLink]="['/versionedit', version.Id]"><md-icon svgIcon="pencil"></md-icon>&nbsp;Edit</a>
            </div>
        </md-card-footer>
    </md-card>
</div>
