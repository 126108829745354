<h2>Product List</h2>
<div class="products-container">
    <md-card class="product-card" *ngFor="let product of products">
        <md-card-header (click)="viewProduct(product.Id)" style="cursor:pointer">
            <img md-card-avatar src="{{product.IconUrl}}" />
            <md-card-title>{{product.Name}}</md-card-title>
            <md-card-subtitle>{{product.UpdateId}}</md-card-subtitle>
        </md-card-header>
        <img md-card-image src="{{product.ImageUrl}}" />
        <md-card-content flex>
            <p>
                {{product.Description}}
            </p>
        </md-card-content>
        <md-card-footer>
            <div class="product-status">
                Status: {{releaseStatus[product.Status][0]}}
            </div>
            <div class="product-button-container">
                <a md-raised-button [routerLink]="['/product', product.Id]"><md-icon svgIcon="information-outline"></md-icon>&nbsp;Details</a>
                <a md-raised-button [routerLink]="['/productedit', product.Id]"><md-icon svgIcon="pencil"></md-icon>&nbsp;Edit</a>
            </div>
        </md-card-footer>
    </md-card>
</div>
